<template>
  <div class="container">
    <div class="credit-statistics">
      <div class="market-overview-box">
        <data-card :tmp-list="headerCard.tempList" :data="headerCard.data">
          <template #btn>
            <div style="margin-left: 20px;display: flex;align-items: center;">
              <Button type="primary" @click="cash">提现</Button>
            </div>
          </template>
        </data-card>
      </div>
    </div>

    <!-- <kdx-header-bar>
      <Form ref="form" :model="searchData" :label-width="100" inline @submit.native.prevent>
        <FormItem label="提现方式：">
          <Select v-model="searchData.pay_type" class="width-160">
            <Option v-for="(item, index) in pay_type" :key="index" :value="item.value">
              {{ item.label }}
            </Option>
          </Select>
        </FormItem>
        <FormItem label="提现状态：">
          <Select v-model="searchData.status" class="width-160">
            <Option v-for="(item, index) in type" :key="index" :value="item.value">
              {{ item.label }}
            </Option>
          </Select>
        </FormItem>
        <FormItem label="申请时间：">
          <DatePicker v-model="selectDate" type="datetimerange" format="yyyy-MM-dd HH:mm" placeholder="请选择"
            class="width-340" :confirm="true"></DatePicker>
        </FormItem>
        <div class="ivu-form-item-btn">
          <Button type="primary" @click="handleSearch">搜索</Button>
          <Button type="text" @click="handleReset">重置</Button>
          <Button type="text" @click="handleExport">导出</Button>
        </div>
      </Form>
    </kdx-header-bar> -->
    <div class="list-wrap" v-loading="loading">
      <div class="custom-table">
        <div class="theader">
          <ul>
            <li class="money">申请金额</li>
            <!-- <li>手续费</li> -->
            <li class="tax-money">实际到账金额</li>
            <li class="account">收款账户</li>
            <li class="status">提现状态</li>
            <li class="setting">操作</li>
          </ul>
        </div>
        <div class="tbody" v-if="list.length">
          <div class="tr" v-for="(item, index) in  list " :key="index">
            <div class="code">
              <div class="text">申请时间：</div>
              {{ new Date(item.date * 1000) | dateFormat() }}
              <!-- <div class="copy" v-clipboard:copy="item.cardNo" v-clipboard:success="onCopySuccess"
                v-clipboard:error="onCopyError">
                复制
              </div> -->
            </div>
            <ul>
              <li class="money">
                <div class="box">￥{{ item.money }}</div>
              </li>
              <!-- <li>
                <div class="box">
                  -
                </div>
              </li> -->
              <li class="tax-money">
                <div class="box">
                  ￥{{ item.taxMoney }}
                </div>
              </li>
              <li class="account">
                <div class="box">
                  开户行：{{ item.bankName }}<br />
                  收款账号：{{ item.cardNo }}<br />
                  账户名：{{ item.name }}<br />
                  纳税识别号：{{ item.idcard }}
                </div>
              </li>
              <li class="status">
                <div>
                  <div
                    :class="['box', { paid: item.isVerify == 1, refuse: item.isVerify == 2, applying: item.isVerify == 0 }]">
                    {{ item.verifyReply }}
                  </div>
                  <div v-if="!item.isVerify == 0">
                    审核时间：{{ new Date(item.verifyTime * 1000) | dateFormat() }}
                  </div>
                </div>


              </li>

              <li class="setting">
                <div class="box" @click="upload(item)">上传发票</div>
              </li>
            </ul>
          </div>
        </div>
        <div class="nodata" v-else>暂无数据</div>
      </div>
      <div class="footer-page">
        <kdx-page-component ref="page" :total="total" @on-change="changePage"></kdx-page-component>
      </div>
    </div>


    <!--提现-->
    <withdraw-cash ref="edit_receiving_goods" @on-refresh="handleRefresh"></withdraw-cash>

    <kdx-modal-frame v-model="uploadShow" title="上传发票" :width="500" @on-cancel="uploadShow = false"
      @on-ok="uploadShow = false" :footerHide="true">

      <div style="padding: 20px 0;">
        <Form ref="form" :model="model" :label-width="140" class="order-edit-receive-goods" @submit.native.prevent>

          <FormItem prop="invoiceId" label="发票上传：">
            <Button style="margin-bottom: 10px;" type="primary" @click="visible = true">开票信息</Button>
            <kdx-image-video v-if="uploadShow" type="image/*,.pdf" :defaultList.sync="invoice" :limit="1" :action="$action"
              @change="invoiceChange" :msg="false" desc="添加图片，支持pdf"></kdx-image-video>
          </FormItem>

          <Modal title="开票信息" v-model="visible">
            <div class="invoice-info">
              <p>掌政云开票信息</p>
              账户名：宁波掌政通新媒体科技有限公司
              <kdx-copy-text text="宁波掌政通新媒体科技有限公司">
                <Button type="text" size="small">复制</Button>
              </kdx-copy-text><br />
              纳税识别号：91330201MA2GRKY97R
              <kdx-copy-text text="91330201MA2GRKY97R">
                <Button type="text" size="small">复制</Button>
              </kdx-copy-text><br />
              地址：浙江省宁波高新区创苑路750号003幢2楼210-609室
              <kdx-copy-text text="浙江省宁波高新区创苑路750号003幢2楼210-609室">
                <Button type="text" size="small">复制</Button>
              </kdx-copy-text><br />
              电话：13396667579
              <kdx-copy-text text="13396667579">
                <Button type="text" size="small">复制</Button>
              </kdx-copy-text><br />
              开户行：宁波银行国家高新区支行
              <kdx-copy-text text="宁波银行国家高新区支行">
                <Button type="text" size="small">复制</Button>
              </kdx-copy-text><br />
              账号：50010122001201629
              <kdx-copy-text text="50010122001201629">
                <Button type="text" size="small">复制</Button>
              </kdx-copy-text><br />
              税点：6%
            </div>
          </Modal>
        </Form>
      </div>
    </kdx-modal-frame>
  </div>
</template>

<script>
import DataCard from "@/components/DataCard";
import WithdrawCash from "./components/withdrawCash";
import { formatDate } from '@/assets/helpers/index';
// import { config as settings } from '@/api/config/settings.js'
import { Export } from '@/helpers/export';

export default {
  inject: ['returnToTop'],
  components: { DataCard, WithdrawCash },
  props: {},
  data() {
    return {
      headerCard: {
        tempList: [
          // {
          //   key: 'total_send',
          //   image: require('@/assets/image/data/data-total-money.png'),
          //   title: '累计提现余额',
          //   prefix: '￥'
          // },
          {
            key: 'total_member',
            image: require('@/assets/image/data/data-surplus.png'),
            title: '账户剩余余额',
            prefix: '￥',
            slot: 'btn'
          }
        ],
        data: {
          total_send: 0.00,
          total_member: 0.00,
        }
      },
      total: 0,
      page: 1,
      pagesize: 10,
      list: [],
      selectDate: ['', ''],
      // 搜索信息
      searchData: {
        startTime: '',
        endTime: '',
        level_id: 'all',
        status: 'all',
        pay_type: 'all',
        keyword: '',
        export: '',
      },
      type: [
        {
          value: 'all',
          label: '全部',
        },
        {
          value: '0',
          label: '待审核',
        },
        {
          value: '10',
          label: '成功',
        },
        {
          value: '11',
          label: '手动打款',
        },
        {
          value: '40',
          label: '提现拒绝',
        },
      ], // 提现状态
      pay_type: [], // 提现方式
      levels: [], // 用户等级
      loading: false,

      uploadShow: false,
      visible: false,
      model: {
        invoiceId: ""
      },
      uploadId: null,
      invoice: [],
    };
  },
  watch: {
    // selectDate: {
    //   handler(value) {
    //     this.searchData.startTime =
    //       value[0] === ''
    //         ? ''
    //         : formatDate(new Date(value[0]), 'yyyy-MM-dd hh:mm:ss');
    //     this.searchData.endTime =
    //       value[1] === ''
    //         ? ''
    //         : formatDate(new Date(value[1]), 'yyyy-MM-dd hh:mm:ss');
    //   },
    //   deep: true,
    //   immediate: true,
    // },
  },
  computed: {
  },
  async created() {
    this.getWithdrawApplyLabel();
    this.getWithdrawList();
    await this.$store.dispatch('alone/getUserInfo');
    const { resIncome } = this.$store.state.alone.user;
    this.headerCard.data.total_member = (resIncome * 1).toFixed(2);
  },
  methods: {
    invoiceChange(val) {
      const id = val.map(e => e.id).join(',');
      console.log(val);
      this.$api.finance.witFp({ id: this.uploadId, fid: id }, { loading: true, toast: 1 });
    },
    upload(item) {
      console.log(item);
      this.uploadId = item.id;
      this.invoice = [];
      if (item.invoiceUrl) {
        this.invoice = [{ url: item.invoiceUrl, path: item.invoiceUrl, status: "finished", name: "发票", id: 1 }];
      }
      this.uploadShow = true;

    },

    cash() {
      this.$refs["edit_receiving_goods"].setValue();
    },
    handleRefresh() { },

    // 获取申请提现筛选标签
    getWithdrawApplyLabel() {
      let res = {
        "error": 0,
        "type": {
          "0": "未支付或待审核",
          "10": "成功",
          "11": "手动打款",
          "20": "失败",
          "30": "已退款",
          "40": "已拒绝"
        },
        "pay_type": {
          "10": "后台",
          "20": "微信",
          "30": "支付宝"
        },
        "levels": {
          "1": "默认等级"
        },
        "groups": {
          "1": "个人",
          "2": "餐厅",
          "3": "公司"
        }
      };
      this.pay_type = Object.entries(res.pay_type).map((item) => {
        return {
          value: item[0],
          label: item[1],
        };
      });
      this.pay_type.unshift({
        value: 'all',
        label: '全部',
      });
      this.levels = Object.entries(res.levels).map((item) => {
        return {
          value: item[0],
          label: item[1],
        };
      });
      this.levels.unshift({
        value: 'all',
        label: '全部',
      });
    },
    // 提现记录列表
    async getWithdrawList() {
      this.returnToTop();
      this.loading = true;
      const { page, pagesize: size } = this;
      const result = await this.$api.finance.witlist({ page, size });
      this.list = result.list;
      this.total = result.count;
      this.loading = false;
    },
    // 点击搜索按钮
    handleSearch() {
      this.page = 1;
      this.pagesize = 10;
      this.$refs['page'].reset();
      this.getWithdrawList();
    },
    // 点击重置按钮
    handleReset() {
      this.selectDate = ['', ''];
      this.searchData.startTime = '';
      this.searchData.endTime = '';
      this.searchData.level_id = 'all';
      this.searchData.status = 'all';
      this.searchData.pay_type = 'all';
      this.searchData.keyword = '';
      this.searchData.export = '';
      this.page = 1;
      this.pagesize = 10;
      this.$refs['page'].reset();
      this.getWithdrawList();
    },
    // 切换页码
    changePage(page) {
      this.page = page.pageNumber;
      this.pagesize = page.pageSize;
      this.getWithdrawList();
    },
    // 复制成功
    onCopySuccess() {
      this.$Message.success('内容已复制到剪切板！');
    },
    // 复制失败
    onCopyError() {
      this.$Message.error('复制失败！');
    },
    // 取消
    handleStatus(data, status) {
      this.$Modal.confirm({
        title: '提示',
        content: "确认取消？",
        onOk: () => {
          this.updateStatus(data, status);
        },
        onCancel: () => { },
      });
    },
    // 手动打款、拒绝
    updateStatus(data, status) {
      this.$api.financeApi
        .updateStatus({
          status: status,
          order_id: data.order_id,
        })
        .then((res) => {
          if (res.error == 0) {
            this.$Message.success('操作成功');
            this.getWithdrawList();
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.credit-statistics {
  margin-top: 20px;
  margin-bottom: 10px;

  .market-overview-box {
    padding: 30px 40px;
    border-radius: 2px;
    background-color: #ffffff;

    /deep/.data-card ul {
      justify-content: space-between;
      padding: 0 5px;

      li {
        width: 33%;
        padding: 10px 5px;
      }
    }
  }
}

.container {
  background-color: #f4f6f8;
  margin: 20px auto;
}

.list-wrap {
  padding: 0 20px;
  background: #fff;
  position: relative;
}

.ivu-form .ivu-form-item {
  margin-bottom: 0;

  &:nth-child(1) {
    margin-bottom: 20px;
  }
}

.custom-table {
  font-size: 14px;
  line-height: 20px;

  ul {
    display: flex;

    li {
      display: flex;
      align-items: center;
      box-sizing: border-box;
      padding-left: 20px;
      flex-shrink: 0;

      &.money {
        width: 140px;
      }

      &.tax-money {
        flex: 1;
      }

      &.account {
        flex: 1;
        min-width: 300px;
      }

      &.status {
        flex: 1;
        min-width: 250px;
      }

      &.setting {
        width: 240px;

        .box {
          color: #1F65D7;
          cursor: pointer;
        }
      }
    }
  }

  .theader {
    ul {
      height: 64px;

      li {
        height: 64px;
        font-weight: bold;
        font-size: 14px;
        line-height: 20px;
        color: #262b30;
      }
    }
  }

  .tbody {
    .tr {
      border: 1px solid #e9edef;
      border-radius: 2px;
      margin-bottom: 20px;

      &:last-child {
        margin-bottom: 0;
      }

      .code {
        padding-left: 20px;
        display: flex;
        align-items: center;
        height: 50px;
        background-color: #f4f6f8;
        color: #939799;

        .text {
          font-weight: bold;
        }

        .copy {
          color: $brand-color;
          margin-left: 10px;
          cursor: pointer;
        }
      }

      ul {
        padding-top: 20px;
        padding-bottom: 20px;



        li.status {
          .box {
            &.applying {
              color: #ff9900;
            }

            &.paid {
              color: #19be6b;
            }

            &.payment {
              color: $brand-color;
            }

            &.refuse {
              color: #ff1f2c;
            }
          }
        }

      }
    }
  }

  .nodata {
    text-align: center;
    color: #262b30;
    font-size: 14px;
    height: 48px;
    line-height: 48px;
    border-top: 1px solid #e8eaec;
    border-bottom: 1px solid #e8eaec;
  }
}

.footer-page {
  border-top: none;
}

.invoice-info {
  padding: 20px;
  font-size: 14px;

  >p {
    text-align: center;
    font-size: 16px;
    margin-bottom: 15px;
    font-weight: bold;
  }
}
</style>
