<template>
  <div class="cash-table">
    <Tabs type="card" v-model="tabsName" @on-click="getList">
      <TabPane :label="`${item.hold_name} ${item.game_title},￥${item.sum_money}`" v-for="(item, index) in hdlist"
        :key="index"></TabPane>
    </Tabs>
    <div class="header-action" v-if="hdlist.length">
      <Checkbox v-model="isSelectAll" @on-change="checkboxChange" :disabled="!table.length"></Checkbox>
      全选（跨页）：共{{ total }}个订单&nbsp;&nbsp;总金额：<span>￥{{ hdlist[tabsName].sum_money }}</span>
    </div>
    <Table class="cash-table2" ref="table" :columns="columns" :data="table2" @on-selection-change="selectChange"
      max-height="400" @on-select="onSelect" @on-select-cancel="onSelectCancel" @on-select-all="onSelectAll"
      @on-select-all-cancel="onSelectAllCancel" row-key="id"></Table>
    <div class="footer-page" v-show="table.length > 0">
      <kdx-page-component ref="page" :current='current' :total="total" @on-change="changePage"></kdx-page-component>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Array,
    }
  },
  watch: {
    selectRows() {
      this.$emit('input', this.selectRows);
    },
    tabsName() {
      this.selectRows = [];
      this.isSelectAll = false;
      const hdid = this.hdlist[this.tabsName].hdid;
      this.$emit('update:hdid', hdid);
    }

  },
  data() {
    return {
      table: [],
      table2: [],
      selectRows: [], // 选中项
      tabsName: 0,
      hdlist: [],
      isSelectAll: false,
      columns: [
        {
          type: 'selection',
          width: 40,
          align: 'center',
        },
        {
          title: '商品',
          key: 'shop',
          minWidth: 260,
          render: (h, params) => {

            return (
              <div class="content-box">
                <div class="content">
                  <div class="content-text">

                    <span class="text">{params.row.title}</span>
                  </div>

                </div>
              </div>
            );
          }
        },
        {
          title: '价格',
          key: 'money',
          minWidth: 100,
          render: (h, params) => {
            return (
              <div class="price">
                <span>￥{params.row[params.column.key]}</span>
              </div>
            );
          }
        },


      ],
      params: {
        page: 1,
        limit: 10
      },
      total: 0,
      current: 1,
    };
  },
  created() {
    this.gethdList();
  },

  methods: {
    selectAll() { },
    // 选中项发生变化
    selectChange(selection) {
      console.log(selection);
      // this.selectRows = selection;
      // this.isSelectAll = this.selectRows.length === this.table.length;
    },
    onSelect(selection, row) {
      console.log(row);
      this.selectRows = [...this.selectRows, row];

      this.isSelectAll = this.selectRows.length === this.table.length;
    },
    onSelectCancel(selection, row) {
      console.log(row);
      this.selectRows = this.selectRows.filter(e => e.id != row.id);
      this.isSelectAll = this.selectRows.length === this.table.length;
    },
    onSelectAll() {
      const selection = this.table.slice((this.params.page - 1) * this.params.limit, this.params.page * this.params.limit);
      this.selectRows = [...this.selectRows, ...selection];
      //根据id去重
      this.selectRows = [...new Map(this.selectRows.map(item => [item.id, item])).values()];

      this.isSelectAll = this.selectRows.length === this.table.length;
    },
    onSelectAllCancel() {
      const selection = this.table.slice((this.params.page - 1) * this.params.limit, this.params.page * this.params.limit);
      this.selectRows = this.selectRows.filter(e => !selection.some(item => item.id == e.id));

      this.isSelectAll = this.selectRows.length === this.table.length;
    },

    checkboxChange(status) {
      this.table.forEach((item, index) => {
        this.$set(this.table[index], '_checked', status);
      });
      if (status) {
        this.selectRows = this.table;
      } else {
        this.selectRows = [];
      }
    },


    async gethdList() {
      const result = await this.$api.finance.witHd({}, { loading: true });
      this.hdlist = result.list;

      const hdid = this.hdlist[this.tabsName].hdid;
      this.$emit('update:hdid', hdid);

      this.getList();
    },
    async getList() {
      const hdid = this.hdlist[this.tabsName].hdid;
      const result = await this.$api.finance.witShopIds({ hdid }, { loading: true });
      this.table = result.list;
      this.total = result.count;



      // const newarr = new Array(100).fill(1).map((item, index) => {
      //   return {
      //     id: index + 1,
      //     title: "测试",
      //     money: 1
      //   };
      // });
      // this.table = this.table.concat(newarr);
      this.total = this.table.length;
      this.table.forEach((item, index) => {
        this.$set(this.table[index], '_checked', false);
      });
      this.getMockData();
    },
    getMockData() {
      this.table2 = this.table.slice((this.params.page - 1) * this.params.limit, this.params.page * this.params.limit);

      this.table2.forEach((item, index) => {
        const findex = this.selectRows.findIndex(e => e.id == item.id);

        this.$set(this.table2[index], '_checked', findex != -1);
      });
    },

    changePage(page) {
      this.params.page = page.pageNumber;
      this.params.limit = page.pageSize;
      this.current = page.pageNumber;
      this.getMockData();
    },
  }

};
</script>

<style lang="scss" scoped>
.header-action {
  padding-left: 15px;
}

.list {
  padding-bottom: 40px;

  /deep/ .ivu-table {
    td {
      .content-box {
        display: flex;
        flex-wrap: nowrap;
        align-items: center;

        .image {
          width: 80px;
          height: 80px;
          margin-right: 10px;
          border: 1px solid $border-color;
          overflow: hidden;
          border-radius: 2px;
          box-sizing: border-box;
          flex-shrink: 0;

          &>img {
            width: 100%;
            height: 100%;
          }
        }

        .content {
          width: 0;
          flex: 1;

          .content-text {
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 3;
            overflow: hidden;
            height: 60px;

            .mark {
              @include font-12-16;
              color: #ed4014;
              background-color: #ffefe6;
              border-radius: 2px;
              margin-right: 4px;
              width: 18px;
              height: 18px;
              display: inline-block;
              text-align: center;
              line-height: 18px;

              &:nth-last-child(2) {
                margin-right: 6px;
              }

              &.real {
                background-color: #F0FAFF;
                color: $brand-color;
              }

              &.virtual {
                background-color: #E6FFF9;
                color: #00C5C5;
              }

              &.secret {
                background-color: #FFCDD0;
                color: #FF000F;
              }
            }

            .text {
              @include font-14-20;
            }
          }

          .label {
            // margin-bottom: -10px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;

            >span {
              border-radius: 2px;
              background-color: $background-color;
              @include font-12-16;
              margin-right: 10px;
              color: $text-second;
              display: inline-block;
              height: 20px;
              line-height: 20px;
              padding: 0 8px;
            }
          }
        }
      }

      // 价格
      .price {
        color: $text-first;
        @include font-14-20-bold;

        .icon {
          display: none;
          cursor: pointer;
          font-size: 16px;
          vertical-align: middle;
          font-weight: normal;
        }

        &:hover {
          >.icon {
            display: inline-block;
          }
        }
      }


      .inventory {
        span {
          font-size: 16px;
        }

        .icon {
          /* display: none; */
          cursor: pointer;
          font-size: 18px;
          vertical-align: middle;
          color: #999;
          margin-left: 5px;
        }

        &:hover {
          >.icon {
            display: inline-block;
          }
        }
      }

      .market-tag {
        .status-text {
          padding-right: 8px;
          cursor: pointer;
        }
      }

      .action {
        margin-right: -8px;

        .ivu-btn-text {
          margin-right: 8px;
        }
      }
    }
  }

  .footer-page {
    border-top: 1px solid $border-color;
  }
}

.edit-information-modal {
  margin-top: 40px;
}

.cash-table {
  width: 90%;
}

.modal-frame-content {
  padding: 0 20px;
}
</style>