<template>
  <kdx-modal-frame v-model="value" title="提现" :width="900" :loading="loading" @on-cancel="handleCancel" @on-ok="handleOk">

    <Form ref="form" :model="model" :rules="rules" :label-width="140" class="order-edit-receive-goods"
      @submit.native.prevent>
      <div class="tips">
        提现账号必须是公账。
      </div>
      <FormItem label="账户余额：">
        {{ money }}元
      </FormItem>
      <!-- <FormItem label="提现金额：" prop="money">
        <kdx-rr-input placeholder="请输入" class="width-430" v-model.number="model.money" number :fixed="0" :min-value="0"
          :max-value="money * 1" />
      </FormItem> -->
      <FormItem label="提现商品订单：" prop="orders">
        <cash-table v-if="value" v-model="model.orders" :hdid.sync="hdid" />
      </FormItem>

      <FormItem label="提现金额：">
        {{ total }}元
      </FormItem>
      <FormItem label="账户名：" prop="name">
        <Input v-model="model.name" show-word-limit maxlength="30" class="width-430" placeholder="请输入"></Input>
      </FormItem>
      <FormItem label="纳税识别号：" prop="idcard">
        <Input v-model="model.idcard" show-word-limit maxlength="50" class="width-430" placeholder="请输入"></Input>
      </FormItem>
      <FormItem label="开户行：" prop="bankName">
        <Input v-model="model.bankName" show-word-limit maxlength="30" class="width-430" placeholder="请输入"></Input>
      </FormItem>
      <FormItem label="收款账号：" prop="cardNo">
        <Input v-model="model.cardNo" show-word-limit maxlength="30" class="width-430" placeholder="请输入"></Input>
      </FormItem>
      <!-- <FormItem prop="invoiceId" label="发票上传：">
        <Button style="margin-bottom: 10px;" type="primary" @click="visible = true">开票信息</Button>
        <kdx-image-video type="image/*,.pdf" :defaultList.sync="invoice" :limit="1" :action="$action"
          @change="invoiceChange" desc="添加图片，支持pdf"></kdx-image-video>
      </FormItem> -->

      <Modal title="开票信息" v-model="visible">
        <div class="invoice-info">
          <p>掌政云开票信息</p>
          账户名：宁波掌政通新媒体科技有限公司
          <kdx-copy-text text="宁波掌政通新媒体科技有限公司">
            <Button type="text" size="small">复制</Button>
          </kdx-copy-text><br />
          纳税识别号：91330201MA2GRKY97R
          <kdx-copy-text text="91330201MA2GRKY97R">
            <Button type="text" size="small">复制</Button>
          </kdx-copy-text><br />
          地址：浙江省宁波高新区创苑路750号003幢2楼210-609室
          <kdx-copy-text text="浙江省宁波高新区创苑路750号003幢2楼210-609室">
            <Button type="text" size="small">复制</Button>
          </kdx-copy-text><br />
          电话：13396667579
          <kdx-copy-text text="13396667579">
            <Button type="text" size="small">复制</Button>
          </kdx-copy-text><br />
          开户行：宁波银行国家高新区支行
          <kdx-copy-text text="宁波银行国家高新区支行">
            <Button type="text" size="small">复制</Button>
          </kdx-copy-text><br />
          账号：50010122001201629
          <kdx-copy-text text="50010122001201629">
            <Button type="text" size="small">复制</Button>
          </kdx-copy-text><br />
          税点：6%
        </div>
      </Modal>
    </Form>
  </kdx-modal-frame>
</template>

<script>
import cashTable from './cashTable.vue';
export default {
  name: 'index',
  components: {
    cashTable,
  },
  data() {
    const moneyRule = (rule, value, callback) => {
      if (!value && value !== 0) {
        return callback(new Error("提现金额必填"));
      }
      if (value < 10) {
        return callback(new Error("最低提现金额为10"));
      } else if (value > this.money * 1) {
        return callback(new Error("金额不足"));
      } else {
        return callback();
      }
    };
    return {
      value: false,
      cacheArea: [], // 缓存地址
      invoice: [],
      model: {
        money: null,
        bankName: "",
        cardNo: "",
        name: "",
        idcard: "",
        invoiceId: "",
        orders: []
      },
      hdid: '',
      visible: false,
      rules: {
        money: [
          { required: true, message: '提现金额必填' },
          { validator: moneyRule, trigger: ["change", "blur"] },
        ],
        bankName: [{ required: true, message: '开户行必填' }],
        name: [{ required: true, message: '账户名必填' }],
        cardNo: [{ required: true, message: '收款账号必填' }],
        // invoiceId: [{ required: true, message: '发票必填' }],
        idcard: [
          { required: true, message: '纳税识别号必填' },
        ],
        orders: [
          { required: true, message: '请添加商品订单' },
        ]
      },
      address: {
        data: [],
        list: [],
      },
      loading: false,
      money: 0,


    };
  },
  created() {

  },
  computed: {
    total() {
      if (!this.model.orders) return 0;
      if (this.model.orders.length == 0) return 0;
      const res = this.model.orders.reduce((a, b) => a + b.money * 1, 0);
      return res.toFixed(2);
    }
  },
  methods: {
    invoiceChange(val) {
      this.model.invoiceId = val.map(e => e.id).join(',');
      this.$refs['form'].validateField('invoiceId');
    },
    setValue() {
      this.value = !this.value;
      if (this.value) {
        this.resetModel();
        this.getData();
      }
    },
    handleCancel() {
      this.setValue();
    },
    handleOk() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          this.submit();
        }
      });
    },
    async submit() {
      const { bankName, cardNo, name, idcard, orders } = this.model;
      const ids = orders.map(e => e.id).join(',');
      await this.$api.finance.witadd({ bankName, cardNo, name, idcard, ids, hdid: this.hdid }, { loading: true, toast: 1 });
      this.value = false;
    },
    async getData() {
      // this.loading = true;
      const { resIncome } = this.$store.state.alone.user;
      this.money = (resIncome * 1).toFixed(2);
      // this.loading = false;
    },
    resetModel() {
      this.model = {
        money: null,
        bankName: "",
        cardNo: "",
        name: "",
        idcard: "",
        invoiceId: "",
        orders: []
      };
    },
  },
};
</script>

<style scoped lang="scss">
.order-edit-receive-goods {
  padding: 30px 0;
}

.tips {
  padding-left: 60px;
  color: red;
}

.invoice-info {
  padding: 20px;
  font-size: 14px;

  >p {
    text-align: center;
    font-size: 16px;
    margin-bottom: 15px;
    font-weight: bold;
  }
}
</style>
